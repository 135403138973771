import type {AvatarState} from "@atg-payment-shared/account-settings-types";
import type {ImageFilePayload} from "@atg-tillsammans-shared/image-types";
import type {FetchAction} from "@atg-shared/fetch-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import {frameAction} from "atg-store-addons";
import * as AvatarApi from "./avatarApi";

export const AVATAR_REMOVE_REQUEST = "AVATAR_REMOVE_REQUEST";
export const AVATAR_REMOVE_RESPONSE = "AVATAR_REMOVE_RESPONSE";
export const AVATAR_REMOVE_RESET = "AVATAR_REMOVE_RESET";

export const AVATAR_SAVE_REQUEST = "AVATAR_SAVE_REQUEST";
export const AVATAR_SAVE_RESPONSE = "AVATAR_SAVE_RESPONSE";
export const AVATAR_SAVE_RESET = "AVATAR_SAVE_RESET";

export const OPEN_CHANGE_AVATAR_MODAL = "OPEN_CHANGE_AVATAR_MODAL";

export type SaveAvatarAction = FetchAction<
    typeof AVATAR_SAVE_REQUEST,
    typeof AVATAR_SAVE_RESPONSE,
    {imageUrl: string},
    AvatarState["upload"]
>;

export type RemoveAvatarAction = FetchAction<
    typeof AVATAR_REMOVE_REQUEST,
    typeof AVATAR_REMOVE_RESPONSE,
    void,
    AvatarState["remove"]
>;

export type SaveAvatarResetAction = {
    type: typeof AVATAR_SAVE_RESET;
};

export type RemoveAvatarResetAction = {
    type: typeof AVATAR_REMOVE_RESET;
};

export type OpenChangeModalAvatarAction = {
    type: typeof OPEN_CHANGE_AVATAR_MODAL;
};

export const saveAvatar = (payload: ImageFilePayload): SaveAvatarAction => ({
    type: FETCH,
    payload: {
        requestAction: AVATAR_SAVE_REQUEST,
        receiveAction: AVATAR_SAVE_RESPONSE,
        callApi: call(AvatarApi.saveAvatar, payload),
    },
});

export const removeAvatar = (): RemoveAvatarAction => ({
    type: FETCH,
    payload: {
        requestAction: AVATAR_REMOVE_REQUEST,
        receiveAction: AVATAR_REMOVE_RESPONSE,
        callApi: call(AvatarApi.removeAvatar),
    },
});

export const resetSaveAvatar = (): SaveAvatarResetAction => ({
    type: AVATAR_SAVE_RESET,
});

export const resetRemoveAvatar = (): RemoveAvatarResetAction => ({
    type: AVATAR_REMOVE_RESET,
});

export const openChangeAvatarModal = (): OpenChangeModalAvatarAction =>
    frameAction({
        type: OPEN_CHANGE_AVATAR_MODAL,
    });

export type AvatarAction =
    | SaveAvatarAction
    | RemoveAvatarAction
    | SaveAvatarResetAction
    | RemoveAvatarResetAction
    | OpenChangeModalAvatarAction;
