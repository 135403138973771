import {combineReducers} from "redux";
import {createFetchReducer, createInitialState} from "@atg-shared/fetch-redux";
import type {SmsRecoveryState} from "@atg-payment-shared/account-settings-types";
import {
    DELETE_SMS_RECOVERY_RESPONSE,
    DELETE_SMS_RECOVERY_REQUEST,
    SAVE_SMS_RECOVERY_REQUEST,
    SAVE_SMS_RECOVERY_RESPONSE,
    RESET_SAVE_SMS_RECOVERY_STATUS,
    RESET_DELETE_SMS_RECOVERY_STATUS,
    FETCH_SMS_RECOVERY_QUESTIONS_REQUEST,
    FETCH_SMS_RECOVERY_QUESTIONS_RESPONSE,
    FETCH_SMS_RECOVERY_ANSWER_REQUEST,
    FETCH_SMS_RECOVERY_ANSWER_RESPONSE,
    type SmsRecoveryAction,
} from "./smsRecoveryActions";

const initialQuestionsState: SmsRecoveryState["questions"] = createInitialState({
    data: [],
});
const questions = createFetchReducer(
    FETCH_SMS_RECOVERY_QUESTIONS_REQUEST,
    FETCH_SMS_RECOVERY_QUESTIONS_RESPONSE,
    null,
    (state, action: SmsRecoveryAction) => {
        switch (action.type) {
            case FETCH_SMS_RECOVERY_QUESTIONS_RESPONSE:
                if (action.error) return state;
                return {
                    ...state,
                    data: action.payload,
                };
            default:
                return state;
        }
    },
    initialQuestionsState,
);

const initialAnswerState: SmsRecoveryState["answer"] = createInitialState({data: {}});
const answer = createFetchReducer(
    FETCH_SMS_RECOVERY_ANSWER_REQUEST,
    FETCH_SMS_RECOVERY_ANSWER_RESPONSE,
    null,
    (state, action: SmsRecoveryAction) => {
        switch (action.type) {
            case FETCH_SMS_RECOVERY_ANSWER_RESPONSE:
                if (action.error) return state;
                return {
                    ...state,
                    data: action.payload.controlQuestion,
                };
            case SAVE_SMS_RECOVERY_RESPONSE: {
                if (action.error) return state;
                return {
                    ...state,
                    data: action.context ?? {},
                };
            }
            case DELETE_SMS_RECOVERY_RESPONSE: {
                if (action.error) return state;
                return {...state, data: {}};
            }
            default:
                return state;
        }
    },
    initialAnswerState,
);

const saveSmsRecovery = createFetchReducer(
    SAVE_SMS_RECOVERY_REQUEST,
    SAVE_SMS_RECOVERY_RESPONSE,
    RESET_SAVE_SMS_RECOVERY_STATUS,
    (state) => state,
    createInitialState({}),
);

const deleteSmsRecovery = createFetchReducer(
    DELETE_SMS_RECOVERY_REQUEST,
    DELETE_SMS_RECOVERY_RESPONSE,
    RESET_DELETE_SMS_RECOVERY_STATUS,
    (state) => state,
    createInitialState({}),
);

export const smsRecoveryReducer = combineReducers({
    questions,
    answer,
    save: saveSmsRecovery,
    delete: deleteSmsRecovery,
});
