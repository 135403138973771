import type {FetchAction} from "@atg-shared/fetch-types";
import type {
    ContactInfo,
    ContactInfoState,
} from "@atg-payment-shared/account-settings-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import * as ContactInfoApi from "./contactInfoApi";

export const SET_CONTACT_INFO_REQUEST = "SET_CONTACT_INFO_REQUEST";
export const SET_CONTACT_INFO_RESPONSE = "SET_CONTACT_INFO_RESPONSE";

export const CLEAR_CONTACT_INFO_ERROR = "CLEAR_CONTACT_INFO_ERROR";
export const RESET_CONTACT_INFO_STATUS = "RESET_CONTACT_INFO_STATUS";

export type SetContactInfoAction = FetchAction<
    typeof SET_CONTACT_INFO_REQUEST,
    typeof SET_CONTACT_INFO_RESPONSE,
    {status: string},
    ContactInfoState,
    ContactInfo
>;

export type ClearContactInfoErrorAction = {
    type: typeof CLEAR_CONTACT_INFO_ERROR;
};

export type ResetContactInfoStatusAction = {
    type: typeof RESET_CONTACT_INFO_STATUS;
};

export const setUserContactInfo = (
    contactInfo: ContactInfoApi.SaveContactInfoPayload,
): SetContactInfoAction => ({
    type: FETCH,
    payload: {
        requestAction: SET_CONTACT_INFO_REQUEST,
        receiveAction: SET_CONTACT_INFO_RESPONSE,
        callApi: call(ContactInfoApi.saveContactInfo, contactInfo),
    },
});

export const clearContactInfoError = (): ClearContactInfoErrorAction => ({
    type: CLEAR_CONTACT_INFO_ERROR,
});

export const resetContactInfoStatus = (): ResetContactInfoStatusAction => ({
    type: RESET_CONTACT_INFO_STATUS,
});

export type ContactInfoAction =
    | SetContactInfoAction
    | ClearContactInfoErrorAction
    | ResetContactInfoStatusAction;
