import {memoize} from "lodash/fp";
import atgRequest from "@atg-shared/fetch";
import {fetchAuthorized} from "@atg-shared/auth";
import {MEMBER_AUTH_URL, USER_RECOVERY_URL} from "@atg-shared/service-url";
import type {AtgResponse} from "@atg-shared/fetch-types";
import TTLCache from "@atg/utils/TTLCache";
import type {
    SmsRecoveryQuestion,
    SmsRecoveryAnswer,
} from "@atg-payment-shared/account-settings-types";

const CONTROL_QUESTION_ANSWER_URL = `${MEMBER_AUTH_URL}/controlquestionanswer`;
const USER_RECOVERY_QUESTIONS_URL = `${USER_RECOVERY_URL}/questions`;

const CACHE_TIME = 30 * 60 * 1000;
const ttlCache: TTLCache<string, SmsRecoveryQuestion> = new TTLCache(CACHE_TIME);

const memoizedAtgRequest = memoize(atgRequest);
memoizedAtgRequest.cache = ttlCache;

export const fetchQuestions = (): Promise<AtgResponse<SmsRecoveryQuestion>> =>
    memoizedAtgRequest(USER_RECOVERY_QUESTIONS_URL);

export const fetchAnswer = (): Promise<AtgResponse<SmsRecoveryAnswer>> =>
    memoizedAtgRequest(CONTROL_QUESTION_ANSWER_URL);

export const saveAnswer = (data: SmsRecoveryAnswer) =>
    fetchAuthorized(CONTROL_QUESTION_ANSWER_URL, {
        method: "PUT",
        body: JSON.stringify(data),
    });

export const deleteAnswer = () =>
    fetchAuthorized(CONTROL_QUESTION_ANSWER_URL, {
        method: "DELETE",
    });

export function clearCache() {
    ttlCache.clear();
}
