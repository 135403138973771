import {t} from "@lingui/macro";
import {RgsLimitTypeEnum, type LimitPeriod, type LimitRowVariants} from "./types";

export const DEBOUNCE_DELAY_IN_MS = 750;

export const netLossLimitContext = {limitType: RgsLimitTypeEnum.LOSS};

function getCaption(limitPeriod: LimitPeriod): string {
    switch (limitPeriod) {
        case "dailyLimit":
            return t`Dagsgräns`;
        case "weeklyLimit":
            return t`Veckogräns`;
        case "monthlyLimit":
            return t`Månadsgräns`;
        default:
            return "";
    }
}

export const limitRowVariants: LimitRowVariants = {
    day: {
        key: "dailyLimit",
        caption: getCaption("dailyLimit"),
    },
    week: {
        key: "weeklyLimit",
        caption: getCaption("weeklyLimit"),
    },
    month: {
        key: "monthlyLimit",
        caption: getCaption("monthlyLimit"),
    },
};
