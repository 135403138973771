import {combineReducers} from "redux";
import {userGamblingResult} from "@atg-responsible-gambling-shared/user-gambling-result-domain";
import {userGamblingSummary} from "@atg-responsible-gambling-shared/user-gambling-summary-domain";
import {limitsReducer as limits} from "@atg-responsible-gambling-shared/limits-domain";
import {reducer as realityCheck} from "@atg-responsible-gambling-shared/reality-check-domain";
import {activeBreakpoint} from "atg-match-media/domain/matchMediaReducer";

const createRootReducer = (asyncReducers: any = {}) =>
    combineReducers<any, any>({
        // reducers that are lazily injected later will end up here
        ...asyncReducers.root,
        activeBreakpoint,
        alert: () => window._frameStore.getState().alert,
        auth: () => window._frameStore.getState().auth,
        accessToken: () => window._frameStore.getState().accessToken,
        router: () => window._frameStore.getState().router,
        user: () => window._frameStore.getState().user,
        modals: () => window._frameStore.getState().modals,
        modalData: () => window._frameStore.getState().modalData,
        memberRegister: () => window._frameStore.getState().memberRegister,
        welcomeNewCustomer: () => window._frameStore.getState().welcomeNewCustomer,
        payment: () => window._frameStore.getState().payment,
        limits,
        userGamblingResult,
        userGamblingSummary,
        realityCheck,
    });

export default createRootReducer;
