import type {GlobalAccountSettingsState as State} from "@atg-payment-shared/account-settings-types";
import {fetchSelectors} from "@atg-shared/fetch-redux";

export const getQuestions = (state: State) =>
    state.accountSettings.smsRecovery.questions.data;
export const getAnswer = (state: State) => state.accountSettings.smsRecovery.answer.data;

const getQuestionsState = (state: State) => state.accountSettings.smsRecovery.questions;
const getAnswerState = (state: State) => state.accountSettings.smsRecovery.answer;

export const {
    isLoading: isFetchingQuestionsLoading,
    isLoaded: isFetchingQuestionsLoaded,
    hasError: hasFetchingQuestionsError,
} = fetchSelectors.createSelectors(getQuestionsState);
export const {
    isLoading: isFetchingAnswerLoading,
    isLoaded: isFetchingAnswerLoaded,
    hasError: hasFetchingAnswerError,
} = fetchSelectors.createSelectors(getAnswerState);

const getSaveState = (state: State) => state.accountSettings.smsRecovery.save;
const getDeleteState = (state: State) => state.accountSettings.smsRecovery.delete;

export const {
    isLoading: isSaveLoading,
    isLoaded: isSaveLoaded,
    hasError: hasSaveError,
} = fetchSelectors.createSelectors(getSaveState);
export const {
    isLoading: isDeleteLoading,
    isLoaded: isDeleteLoaded,
    hasError: hasDeleteError,
} = fetchSelectors.createSelectors(getDeleteState);
