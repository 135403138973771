import type {AnyAction} from "redux";
import {combineReducers} from "redux";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import {LOGOUT_FINISHED} from "@atg-global-shared/user/userActionTypes";
import type {FetchReducer} from "@atg-shared/fetch-types";
import type {CardState} from "@atg-payment-shared/account-settings-types";
import type {UserAction} from "@atg-global-shared/user/userActions";
import type {Action} from "../types";
import {
    REQUEST_CARD,
    REQUEST_NEW_CARD,
    REQUEST_BLOCK_CARD,
    RECEIVE_CARD,
    RECEIVE_NEW_CARD,
    RECEIVE_BLOCK_CARD,
    RESET_STATUS,
    type CardFetchAction,
    type NewCardFetchAction,
    type BlockCardFetchAction,
} from "./cardActions";

const current: FetchReducer<CardState, CardFetchAction | AnyAction> = createFetchReducer(
    REQUEST_CARD,
    RECEIVE_CARD,
    RESET_STATUS,
    (state: CardState, action: Action | UserAction) => {
        // Clear card data on logout
        if (action.type === LOGOUT_FINISHED) return {card: null};

        if (
            action.type === RECEIVE_CARD ||
            action.type === RECEIVE_NEW_CARD ||
            action.type === RECEIVE_BLOCK_CARD
        ) {
            if (action.error) return state;

            return {
                card: action.payload,
            };
        }

        return state;
    },
    {card: null},
);

// Use empty object to allow augmentation of the type by FetchReducer but
// disallow other unknown keys on the state.
// eslint-disable-next-line @typescript-eslint/ban-types
type EmptyObject = {};

const order: FetchReducer<EmptyObject, NewCardFetchAction | AnyAction> =
    createFetchReducer(
        REQUEST_NEW_CARD,
        RECEIVE_NEW_CARD,
        RESET_STATUS,
        (state) => state,
        {},
    );

const block: FetchReducer<EmptyObject, BlockCardFetchAction | AnyAction> =
    createFetchReducer(
        REQUEST_BLOCK_CARD,
        RECEIVE_BLOCK_CARD,
        RESET_STATUS,
        (state) => state,
        {},
    );

export const cardReducer = combineReducers({
    current,
    order,
    block,
});
