/* eslint-disable no-param-reassign */
import type {PayloadAction} from "@reduxjs/toolkit";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {v4 as uuid} from "uuid";
import {pureFetch} from "@atg-shared/fetch";
import {
    CANCELLED_LOGIN_FLOW,
    START_AUTHENTICATION_FLOW,
} from "@atg-shared/auth/domain/authActions";
import {MODAL_CLOSE} from "atg-modals/modalActionTypes";
import type {
    ChatLog,
    Message,
    QuickQuestion,
    QuickQuestionsRequest,
    QuickQuestionsResponse,
    Race,
    Status,
} from "../types";
import {UrlBuilder} from "./urlBuilderElli";
import {clearChatStorage, setChatStorage} from "./storage";
import {set} from "lodash";

export interface ElliState {
    messages: Record<string, Message>;
    status: Status;
    enableScrollToBottom: boolean;
    showScrollDownArrow: boolean;
    questions: {
        loading: boolean;
        error?: string;
        data: QuickQuestion[];
        lastIcebreaker?: QuickQuestion;
    };
    chatLogs: ChatLog[];
    race?: Race;
    isAuthenticating: boolean;
    isOnboarding: boolean;
}

export interface IStore {
    elliAssistant: ElliState;
}

const initialState = {
    messages: {},
    status: "none",
    enableScrollToBottom: true,
    showScrollDownArrow: false,
    questions: {
        loading: false,
        data: [],
    },
    chatLogs: [],
    isAuthenticating: false,
    isOnboarding: false,
} satisfies ElliState as ElliState;

export const getQuestions = createAsyncThunk(
    "elli/quick-questions",
    async (params?: QuickQuestionsRequest) => {
        const ub = new UrlBuilder().unAuthorized().append("quick-questions");

        if (params) {
            ub.withParams(params);
        }

        const url = ub.build();

        const {data} = await pureFetch<QuickQuestionsResponse>(url);
        return data;
    },
);

const elliSlice = createSlice({
    name: "elli",
    initialState,
    reducers: {
        rehydrateMessages(state, action: PayloadAction<Record<string, Message>>) {
            state.messages = action.payload;
        },
        addClientMessage(state, action: PayloadAction<string>) {
            const id = uuid();
            state.messages[id] = {
                id,
                content: action.payload,
                sender: "user",
            };

            setChatStorage(state.messages);
        },
        addMessage(state, action: PayloadAction<Omit<Message, "sender">>) {
            const {id, isLoading, content} = action.payload;
            const existingMessage = state.messages[id];

            if (existingMessage) {
                existingMessage.content += content;
                existingMessage.isLoading = isLoading;
            } else {
                state.messages[id] = {
                    ...action.payload,
                    sender: "assistant",
                };
            }
        },
        setStreaming(state, action: PayloadAction<{id: string; isStreaming: boolean}>) {
            const {id, isStreaming} = action.payload;
            const existingMessage = state.messages[id];

            if (existingMessage) {
                existingMessage.isStreaming = isStreaming;
            }

            setChatStorage(state.messages);
        },
        addChatLog(state, action: PayloadAction<ChatLog>) {
            // Chatlogs have to alternate between user and assistant, if the incoming chatlog is the same as the last one, we update it
            if (state.chatLogs[state.chatLogs.length - 1]?.role === action.payload.role) {
                state.chatLogs[state.chatLogs.length - 1] = action.payload;
                // Other wise we add it to the end of the array, and slice it to only show the last 20 messages
            } else {
                state.chatLogs = [...state.chatLogs, action.payload].slice(-20);
            }
        },
        setElliStatus(state, action: PayloadAction<Status>) {
            state.status = action.payload;
        },
        toggleElli(state) {
            const nextStatus = state.status === "open" ? "close" : "open";
            state.isOnboarding = false;
            state.status = nextStatus;
        },
        resetChat(state) {
            state.messages = {};
            state.chatLogs = [];
            state.enableScrollToBottom = true;
            state.showScrollDownArrow = false;
            state.isOnboarding = false;
            state.questions = initialState.questions;

            clearChatStorage();
        },
        setElliRace(state, action: PayloadAction<Race | undefined>) {
            state.race = action.payload;
        },
        resetElli(state) {
            clearChatStorage();
            return {...initialState, race: state.race};
        },
        setElliError(state, action: PayloadAction<any>) {
            state.questions.error = action.payload;
            state.questions.loading = false;
        },
        setEnableScrollToBottom(state, action: PayloadAction<boolean>) {
            state.enableScrollToBottom = action.payload;
        },
        setShowScrollDownArrow(state, action: PayloadAction<boolean>) {
            state.showScrollDownArrow = action.payload;
        },
        setLastIcebreaker(state, action: PayloadAction<QuickQuestion>) {
            state.questions.lastIcebreaker = action.payload;
        },
        setElliOnboarding(state, action: PayloadAction<boolean>) {
            state.isOnboarding = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestions.fulfilled, (state, action) => {
                state.questions.data = action.payload;
                state.questions.loading = false;
                state.questions.error = undefined;
            })
            .addCase(getQuestions.rejected, (state) => {
                state.questions.loading = false;
                state.questions.error = "Failed to fetch questions";
            })
            .addCase(getQuestions.pending, (state) => {
                state.questions.loading = true;
            })
            .addCase(START_AUTHENTICATION_FLOW, (state) => {
                state.isAuthenticating = true;
            })
            .addCase(CANCELLED_LOGIN_FLOW, (state) => {
                state.isAuthenticating = false;
            })
            .addCase(MODAL_CLOSE, (state, action: any) => {
                if (action?.payload === "userGamblingSummaryModal") {
                    state.isAuthenticating = false;
                }
            });
    },
});

export const {
    rehydrateMessages,
    addClientMessage,
    addMessage,
    addChatLog,
    setElliStatus,
    toggleElli,
    resetChat,
    resetElli,
    setElliRace,
    setStreaming,
    setElliError,
    setEnableScrollToBottom,
    setShowScrollDownArrow,
    setLastIcebreaker,
    setElliOnboarding,
} = elliSlice.actions;

export default elliSlice.reducer;
