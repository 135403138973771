import {combineReducers} from "redux";
import type {ChangePasswordActions} from "./changePasswordActions";
import {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_RESPONSE,
    CHANGE_PASSWORD_CLEAR,
} from "./changePasswordActions";

function changePasswordLoading(state = false, action: ChangePasswordActions) {
    switch (action.type) {
        case CHANGE_PASSWORD:
            return true;
        case CHANGE_PASSWORD_RESPONSE:
            return false;
        default:
            return state;
    }
}

function changePasswordError(state = {}, action: ChangePasswordActions) {
    switch (action.type) {
        case CHANGE_PASSWORD_RESPONSE:
            if ("error" in action && action.error) return action.payload;
            return {};
        case CHANGE_PASSWORD_CLEAR:
            return {};
        default:
            return state;
    }
}

export const changePasswordReducer = combineReducers({
    loading: changePasswordLoading,
    error: changePasswordError,
});
