export const DepositActionConstants = {
    CANCEL_DEPOSIT_IN_IFRAME: "deposit/CANCEL_DEPOSIT_IN_IFRAME",
    CANCEL_SWISH_DIRECT: "deposit/CANCEL_SWISH_DIRECT",

    CLEAR_IFRAME_DEPOSIT_STATE: "deposit/CLEAR_IFRAME_DEPOSIT_STATE",
    CLEAR_STATUS_MESSAGES: "deposit/CLEAR_STATUS_MESSAGES",
    CLEAR_GAME_INFO: "deposit/CLEAR_GAME_INFO",
    CLEAR_DEPOSIT_ANALYTICS: "deposit/CLEAR_DEPOSIT_ANALYTICS",

    DELETE_BANK_CARD: "deposit/DELETE_BANK_CARD",
    DELETE_BANK_CARD_SUCCESS: "deposit/DELETE_BANK_CARD_SUCCESS",
    DELETE_BANK_CARD_FAILURE: "deposit/DELETE_BANK_CARD_FAILURE",

    DEPOSIT_MONEY_SWISH: "deposit/DEPOSIT_MONEY_SWISH",
    DEPOSIT_MONEY_IFRAME: "deposit/DEPOSIT_MONEY_IFRAME",
    // For the app
    DEPOSIT_START: "deposit/DEPOSIT_START",
    DEPOSIT_SWISH_DIRECT_START: "deposit/DEPOSIT_SWISH_DIRECT_START",
    DEPOSIT_FINALIZE: "deposit/DEPOSIT_FINALIZE",
    DEPOSIT_FINALIZE_IFRAME: "deposit/DEPOSIT_FINALIZE_IFRAME",
    DEPOSIT_SUCCESS: "deposit/DEPOSIT_SUCCESS",
    DEPOSIT_FAILURE: "deposit/DEPOSIT_FAILURE",
    DEPOSIT_PENDING: "deposit/DEPOSIT_PENDING",
    DEPOSIT_BLOCKED: "deposit/DEPOSIT_BLOCKED",
    // For the app
    DEPOSIT_CLOSED: "deposit/DEPOSIT_CLOSED",

    DEPOSIT_FLOW_STARTED: "deposit/DEPOSIT_FLOW_STARTED",
    DEPOSIT_FLOW_FINISHED: "deposit/DEPOSIT_FLOW_FINISHED",

    FETCH_OPTIONS: "deposit/FETCH_OPTIONS",
    FETCH_OPTIONS_SUCCESS: "deposit/FETCH_OPTIONS_SUCCESS",
    FETCH_OPTIONS_FAILURE: "deposit/FETCH_OPTIONS_FAILURE",

    RESET_DEPOSIT_IS_PENDING: "deposit/RESET_DEPOSIT_IS_PENDING",

    SET_SWISH_DIRECT_ID: "deposit/SET_SWISH_DIRECT_ID",
    SET_DEPOSIT_AMOUNT: "deposit/SET_DEPOSIT_AMOUNT",
    SET_DEPOSIT_FLOW: "deposit/SET_DEPOSIT_FLOW",
    SET_DEPOSIT_LIMITS_DONE: "deposit/SET_DEPOSIT_LIMITS_DONE",
    SET_SELECTED_OPTION: "deposit/SET_SELECTED_OPTION",
    SET_SWISH_DIRECT_PHONE_NUMBER: "deposit/SET_SWISH_DIRECT_PHONE_NUMBER",
    SET_SWISH_DIRECT_STEP: "deposit/SET_SWISH_DIRECT_STEP",
    SET_GAME_INFO: "deposit/SET_GAME_INFO",
    SET_PRE_SELECTED_BTN_NUMBER: "deposit/SET_PRE_SELECTED_BTN_NUMBER",

    START_DEPOSIT_FLOW: "deposit/START_DEPOSIT_FLOW",
    START_DEPOSIT_FLOW_IN_MODAL: "deposit/START_DEPOSIT_FLOW_IN_MODAL",
    START_IFRAME_DEPOSIT: "deposit/START_IFRAME_DEPOSIT",
    START_ABORT_OBSERVER: "deposit/START_ABORT_OBSERVER",

    SWISH_DIRECT_STEP: "deposit/SWISH_DIRECT_STEP",
    SWISH_DIRECT_PHONE_NUMBER: "deposit/SWISH_DIRECT_PHONE_NUMBER",

    WAIT_FOR_DEPOSIT_LIMITS_TO_BE_SET: "deposit/WAIT_FOR_DEPOSIT_LIMITS_TO_BE_SET",
} as const;

export const DepositMethods = {
    newCard: "newCard",
    existingCard: "existingCard",
    "swish-e-commerce": "swish-e-commerce",
    trustly: "trustly",
} as const;
