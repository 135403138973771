import {persistReducer} from "redux-persist";
// @ts-expect-error no ts declaration
import storage from "redux-persist/lib/storage/index";
import {RouterActions} from "@atg-shared/router";
import {Paths} from "@atg-tillsammans-shared/navigation";
import {LOCK_CONTEXT, UNLOCK_CONTEXT, type VerticalAction} from "./verticalsActions";
import {baseRoutes} from "./useVerticals";

export const CONTEXT_HORSE = "CONTEXT_HORSE";
export const CONTEXT_SPORT = "CONTEXT_SPORT";
export const CONTEXT_CASINO = "CONTEXT_CASINO";
export const CONTEXT_TILLSAMMANS = "CONTEXT_TILLSAMMANS";
export const CONTEXT_SHARES = "CONTEXT_SHARES";
export const CONTEXT_HARRY_BOY = "CONTEXT_HARRY_BOY";

export type VerticalContext =
    | typeof CONTEXT_HORSE
    | typeof CONTEXT_SPORT
    | typeof CONTEXT_CASINO
    | typeof CONTEXT_TILLSAMMANS
    | typeof CONTEXT_SHARES
    | typeof CONTEXT_HARRY_BOY;

export type VerticalState = {
    context: VerticalContext;
    lockContext: boolean;
};

export type Action = {
    type: typeof RouterActions.ROUTER_ON_LOCATION_CHANGED;
    payload: {
        location: {
            pathname: string;
        };
    };
};

const initialState = {
    context: CONTEXT_HORSE as VerticalContext,
    lockContext: false,
};

const getContext = (pathname: string, state: VerticalState): VerticalState => {
    /**
     * The lockContext was created to solve the issue CUST-222.
     * The lockContext state was implemented to lock the context when
     * the user clicks in **Butiksandelar** in the sport context.
     * Note: This state will not be persist for persistReducer because is include in the blackList.
     */
    if (state.lockContext)
        return {
            context: state.context,
            lockContext: state.lockContext,
        };

    if (
        pathname.startsWith("/sport") ||
        pathname === "/konto/mina-spel/sportbok" ||
        pathname === "/konto/mina-spel/sportpool" ||
        pathname === "/konto/kuponger/sportpool"
    )
        return {
            context: CONTEXT_SPORT,
            lockContext: false,
        };

    if (pathname.startsWith("/casino"))
        return {
            context: CONTEXT_CASINO,
            lockContext: false,
        };

    if (baseRoutes.shops.some((sub) => pathname.includes(sub))) {
        return {context: CONTEXT_SHARES, lockContext: false};
    }

    if (pathname.startsWith("/harryboy"))
        return {context: CONTEXT_HARRY_BOY, lockContext: false};

    if (pathname.startsWith(Paths.TILLSAMMANS_ROOT_PATH))
        return {context: CONTEXT_TILLSAMMANS, lockContext: false};

    return {
        context: CONTEXT_HORSE,
        lockContext: false,
    };
};

const verticalsReducer = (
    state: VerticalState = initialState,
    action: Action | VerticalAction,
): VerticalState => {
    let context;

    switch (action.type) {
        case LOCK_CONTEXT:
            return {
                ...state,
                lockContext: true,
            };
        case UNLOCK_CONTEXT:
            return {
                ...state,
                lockContext: false,
            };
        case RouterActions.ROUTER_ON_LOCATION_CHANGED:
            // pathname "/konto" should return state as it is
            // However "/konto/mina-spel" should return CONTEXT_HORSE
            // And "/konto/mina-spel/sportbok" should return CONTEXT_SPORT
            if (
                (action.payload.location.pathname.startsWith("/play") ||
                    action.payload.location.pathname.startsWith("/konto")) &&
                !action.payload.location.pathname.startsWith("/konto/mina-spel") &&
                !action.payload.location.pathname.startsWith("/konto/kuponger")
            )
                return state;

            context = getContext(action.payload.location.pathname, state);

            if (context.context === state.context) return state;

            return {...context};
        default:
            return state;
    }
};

const verticalPersistConfig = {
    storage,
    key: "verticals",
    version: 0,
    blacklist: ["lockContext"],
};

export default persistReducer(verticalPersistConfig, verticalsReducer);
