import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {CardState, Card} from "@atg-payment-shared/account-settings-types";
import * as CardApi from "./cardApi";

export const REQUEST_CARD = "card/REQUEST_CARD";
export const RECEIVE_CARD = "card/RECEIVE_CARD";
export const REQUEST_NEW_CARD = "card/REQUEST_NEW_CARD";
export const RECEIVE_NEW_CARD = "card/RECEIVE_NEW_CARD";
export const REQUEST_BLOCK_CARD = "card/REQUEST_BLOCK_CARD";
export const RECEIVE_BLOCK_CARD = "card/RECEIVE_BLOCK_CARD";
export const RESET_STATUS = "card/RESET_STATUS";

export type CardFetchAction = FetchAction<
    typeof REQUEST_CARD,
    typeof RECEIVE_CARD,
    Card,
    CardState
>;

type ResetStatusAction = {
    type: typeof RESET_STATUS;
};

export type NewCardFetchAction = FetchAction<
    typeof REQUEST_NEW_CARD,
    typeof RECEIVE_NEW_CARD,
    Card,
    CardState
>;

export type BlockCardFetchAction = FetchAction<
    typeof REQUEST_BLOCK_CARD,
    typeof RECEIVE_BLOCK_CARD,
    Card,
    CardState
>;

export const fetchCard = (): CardFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_CARD,
        receiveAction: RECEIVE_CARD,
        callApi: call(CardApi.fetchCard),
    },
});

export const orderNewCard = (): NewCardFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_NEW_CARD,
        receiveAction: RECEIVE_NEW_CARD,
        callApi: call(CardApi.orderCard),
    },
});

export const blockCard = (): BlockCardFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_BLOCK_CARD,
        receiveAction: RECEIVE_BLOCK_CARD,
        callApi: call(CardApi.blockCard),
    },
});

export const resetStatus = (): ResetStatusAction => ({
    type: RESET_STATUS,
});

export type CardAction =
    | CardFetchAction
    | NewCardFetchAction
    | BlockCardFetchAction
    | ResetStatusAction;
