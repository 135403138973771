import type {AnyAction, Reducer} from "redux";
import {combineReducers} from "redux";
import type {AccountSettingsState} from "@atg-payment-shared/account-settings-types";
import {cardReducer} from "./card/cardReducer";
import {contactOptionsReducer} from "./contact-options/contactOptionsReducer";
import {contactInfoReducer} from "./contact-info/contactInfoReducer";
import {changePasswordReducer} from "./change-password/changePasswordReducer";
import {twoFactorReducer} from "./two-factor/twoFactorReducer";
import {avatarReducer} from "./avatar/avatarReducer";
import {smsRecoveryReducer} from "./sms-recovery/smsRecoveryReducer";

export const accountSettingsReducer: Reducer<AccountSettingsState, AnyAction> =
    combineReducers({
        contactInfo: contactInfoReducer,
        contactOptions: contactOptionsReducer,
        card: cardReducer,
        changePassword: changePasswordReducer,
        smsRecovery: smsRecoveryReducer,
        twoFactor: twoFactorReducer,
        avatar: avatarReducer,
    });
