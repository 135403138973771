import type {FetchState} from "@atg-shared/fetch-types";

export type Maybe<T> = T | null | undefined;

export type Prettify<T> = {
    [K in keyof T]: T[K];
};

export type RgsLimitPeriod = "day" | "week" | "month";

export type PeriodicLimit = {
    limit: number;
    remaining: number;
    futureLimit?: number;
    futureActivationTime?: string;
};

export type MaxLimitType =
    | "CUSTOMER_SERVICE"
    | "BASE_LIMIT_YOUNGEST_ADULT"
    | "BASE_LIMIT_YOUNG_ADULT"
    | "BASE_LIMIT_ADULT";

export type MaxLimit = {
    maxLimitAmount: number;
    maxLimitType: MaxLimitType;
};

// A user is not allowed to have partial deposit limits set (See SPECTRA-71 for more information). Type should probably be updated going forward.
export type MultiPeriodicLimit = {
    businessOperationId?: string;
    depositLimits?: {
        dailyLimit?: PeriodicLimit;
        weeklyLimit?: PeriodicLimit;
        monthlyLimit?: PeriodicLimit;
    };
    dailyLimit?: PeriodicLimit;
    weeklyLimit?: PeriodicLimit;
    monthlyLimit?: PeriodicLimit;
    maxLimits?: Array<MaxLimit>;
};

export type LoginTimeLimitResponse = {
    loginTimeLimits: RgsVerticalLimit;
};

export type LegacyCasinoLimits = {
    limit?: number;
    remaining?: number;
    futureLimit?: number;
    futureActivationTime?: string;
} & MultiPeriodicLimit;

export type WeeklyBettingLimit = {
    limit: number;
    remaining: number;
    futureLimit?: number;
    futureActivationTime?: string;
};

export type RgsPeriodicLimit = {
    limit: number | null;
    remaining: number | null;
    validFrom: string;
    validTo: string | null;
    upcoming: RgsPeriodicLimit | null;
    createdBy: string;
    maxLimit?: number;
};

export enum VerticalEnum {
    CASINO = "CASINO",
    HORSE_BETTING = "HORSE_BETTING",
    SPORTSBOOK = "SPORTSBOOK",
}

export type NetlossVertical = "casino" | "horse" | "sport";

export enum RgsLimitTypeEnum {
    LOSS = "netLossLimits",
    TIME = "playTimeLimits",
    LOGIN = "loginTimeLimits",
}

type PartialRgsPeriodicLimit = Partial<Record<RgsLimitPeriod, RgsPeriodicLimit>>;

export type RgsVerticalLimit = Prettify<
    PartialRgsPeriodicLimit & {
        set?: boolean;
    }
>;

export type SimplifiedRgsLimits = Record<NetlossVertical, number>;

export type NullableSimplifiedRgsLimits = {
    [K in keyof SimplifiedRgsLimits]: SimplifiedRgsLimits[K] | null;
};

export type RgsLimit = {
    CASINO?: FetchState<RgsVerticalLimit>;
    HORSE_BETTING?: FetchState<RgsVerticalLimit>;
    SPORTSBOOK?: FetchState<RgsVerticalLimit>;
};

export type RgsLimits = {
    netLossLimits: RgsLimit;
    playTimeLimits: RgsLimit;
    loginTimeLimits: FetchState<RgsVerticalLimit>;
};

export type LimitPeriod = "weeklyLimit" | "dailyLimit" | "monthlyLimit";

type LimitRowVariant = {
    key: LimitPeriod;
    caption: string;
};

export type CalendarPeriod = "day" | "week" | "month";

export type LimitRowVariants = Record<CalendarPeriod, LimitRowVariant>;
