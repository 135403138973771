import {fetchAuthorized} from "@atg-shared/auth";
import {MEMBER_AUTH_URL} from "@atg-shared/service-url";
import type {ChangePasswordParams} from "./changePasswordActions";

export const CHANGE_PASSWORD = `${MEMBER_AUTH_URL}/password`;

export const changePassword = (data: ChangePasswordParams) =>
    fetchAuthorized(CHANGE_PASSWORD, {
        method: "PUT",
        body: JSON.stringify(data),
    });
