import {t} from "@lingui/macro";
import {put} from "redux-saga/effects";
import {AtgAlertTypes} from "@atg-global-shared/alerts-types";
import * as ToastActions from "atg-ui-toast/domain/toastActions";

export function* successToast() {
    yield put(
        ToastActions.showToast({
            type: AtgAlertTypes.SUCCESS,
            message: t`Dina inställningar är uppdaterade.`,
        }),
    );
}

export function* errorToast() {
    yield put(
        ToastActions.showToast({
            type: AtgAlertTypes.DANGER,
            message: t`Ett fel uppstod.`,
        }),
    );
}
