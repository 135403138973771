import {combineReducers} from "redux";
import type {TwoFactorError} from "@atg-payment-shared/account-settings-types";
import {
    TWO_FACTOR_CONFIRMATION,
    TWO_FACTOR_CONFIRMATION_CLEAR,
    TWO_FACTOR_ACTIVATION,
    TWO_FACTOR_ACTIVATION_RESPONSE,
    TWO_FACTOR_ACTIVATION_CLEAR,
    TWO_FACTOR_DEACTIVATION,
    TWO_FACTOR_DEACTIVATION_CLEAR,
    TWO_FACTOR_CONFIRMATION_RESPONSE,
    TWO_FACTOR_DEACTIVATION_RESPONSE,
    GET_TWO_FACTOR_LOGIN_SETTING_REQUEST,
    GET_TWO_FACTOR_LOGIN_SETTING_RESPONSE,
} from "./twoFactorActions";
import type {TwoFactorAction as Action} from "./twoFactorActions";

const twoFactorLoginSetting = (state = false, action: Action) => {
    switch (action.type) {
        case GET_TWO_FACTOR_LOGIN_SETTING_RESPONSE:
            if (action.error) return state;
            return action.payload.smsVerificationLogin;

        case TWO_FACTOR_CONFIRMATION_RESPONSE:
            if (action.error) return state;
            if (action.payload.loginData.status === "OK") return true;
            return state;

        case TWO_FACTOR_DEACTIVATION_RESPONSE:
            if (action.error) return state;
            if (action.payload.loginData.status === "OK") return false;
            return state;

        default:
            return state;
    }
};

function twoFactorLoading(state = false, action: Action) {
    switch (action.type) {
        case TWO_FACTOR_CONFIRMATION:
        case TWO_FACTOR_ACTIVATION:
        case TWO_FACTOR_DEACTIVATION:
        case GET_TWO_FACTOR_LOGIN_SETTING_REQUEST:
            return true;
        case TWO_FACTOR_CONFIRMATION_RESPONSE:
        case TWO_FACTOR_ACTIVATION_RESPONSE:
        case TWO_FACTOR_DEACTIVATION_RESPONSE:
        case GET_TWO_FACTOR_LOGIN_SETTING_RESPONSE:
            return false;
        default:
            return state;
    }
}

function twoFactorError(state: TwoFactorError = {}, action: Action) {
    switch (action.type) {
        case TWO_FACTOR_ACTIVATION_RESPONSE:
        case TWO_FACTOR_CONFIRMATION_RESPONSE:
        case TWO_FACTOR_DEACTIVATION_RESPONSE:
        case GET_TWO_FACTOR_LOGIN_SETTING_RESPONSE:
            return action.error ? {message: action.payload.message} : {};
        case TWO_FACTOR_CONFIRMATION_CLEAR:
        case TWO_FACTOR_ACTIVATION_CLEAR:
        case TWO_FACTOR_DEACTIVATION_CLEAR:
            return {};
        default:
            return state;
    }
}

export const twoFactorReducer = combineReducers({
    isEnabled: twoFactorLoginSetting,
    loading: twoFactorLoading,
    error: twoFactorError,
});
