import {getMarketConfig} from "@atg-shared/market-config";
import type {GameType} from "@atg-shop-shared/types/game";
import * as TillsammansSharedUtils from "@atg-tillsammans-shared/utils";

export {getGameTypeAndWeekDay} from "@atg-tillsammans-shared/utils";

export const toGameType = (gameType: string): GameType =>
    TillsammansSharedUtils.toGameType(gameType) as GameType;

export const parseGameType = (gameId: string | null | undefined): GameType =>
    TillsammansSharedUtils.parseGameType(gameId) as GameType;

export const formatGameType = (
    gameType: GameType,
    {uppercase}: TillsammansSharedUtils.GameTypeFormatOptions = {uppercase: false},
): string => TillsammansSharedUtils.formatGameType(gameType, {uppercase});

export const formatResidualRatio = (residualRatio: number): string =>
    new Intl.NumberFormat(getMarketConfig().defaultLocale, {
        maximumFractionDigits: 4,
    }).format(residualRatio);
