import {combineReducers, type Reducer} from "redux";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {FetchReducer, FetchState} from "@atg-shared/fetch-types";
import {
    SHARE_ROUND_ACTIVATE_RESPONSE,
    SHARE_ROUND_EDIT_RESPONSE,
    SHARE_ROUND_EDIT_DEFAULT_RESPONSE,
    SHARE_SELL_OPEN_RESPONSE,
    SHARE_SELL_CLOSE_RESPONSE,
} from "@atg-shop-shared/share-round-redux-types/actionTypes";
import type {ShopShare} from "@atg-shop-shared/types/shopShare";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as ShopShareActionTypes from "@atg-shop-shared/shops-domain-types/shares.action.types";
// Types need to be extracted from atg-horse-bet
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {Action} from "@atg-shop-shared/shops-domain-types";
import * as UserActions from "@atg-global-shared/user/userActionTypes";

type ShopShareFetchState = {
    shopShare: ShopShare | null;
};

const shopShare: FetchReducer<
    ShopShareFetchState,
    ShopShareActionTypes.ShopShareFetchAction
> = createFetchReducer(
    ShopShareActionTypes.REQUEST_SHOP_SHARE,
    ShopShareActionTypes.RECEIVE_SHOP_SHARE,
    ShopShareActionTypes.RESET_SHOP_SHARE_STATUS,
    (state: ShopShareFetchState, action) => {
        switch (action.type) {
            case ShopShareActionTypes.RECEIVE_SHOP_SHARE:
            case ShopShareActionTypes.CANCEL_AND_REFUND_SHOP_SHARE_RESPONSE:
            case SHARE_ROUND_ACTIVATE_RESPONSE:
            case SHARE_ROUND_EDIT_RESPONSE:
            case SHARE_SELL_CLOSE_RESPONSE:
                if (action.error) return state;

                return {
                    shopShare: action.payload,
                };
            case SHARE_ROUND_EDIT_DEFAULT_RESPONSE:
                if (action.error) return state;

                return {
                    ...state,
                    shopShare: {
                        ...state.shopShare,
                        autoActivate: action.context.autoActivate,
                        autoActivateDays: action.context.autoActivateDays,
                    },
                };

                return state;
            case SHARE_SELL_OPEN_RESPONSE:
                if (action.error) return state;

                if (!action.context.updateShopShareState) return state;

                return {
                    shopShare: action.payload,
                };
            case ShopShareActionTypes.SHOP_SHARE_COUPON_VISIBILITY_RESPONSE:
                if (action.error) return state;
                return {
                    shopShare: {
                        ...state.shopShare,
                        isCouponPublic: action.context && action.context.isCouponPublic,
                    },
                };
            case ShopShareActionTypes.SET_COUPON_ID:
                return {
                    ...state,
                    shopShare: {
                        ...state.shopShare,
                        couponId: action.payload.couponId,
                    },
                };
            case UserActions.LOGOUT_FINISHED:
            case ShopShareActionTypes.RESET_SHOP_SHARE_STATUS:
                return {
                    shopShare: null,
                };
            default:
                return state;
        }
    },
    {
        shopShare: null,
    },
);

type ShopShareCouponDetailsFetchState = {
    shopShareCouponDetails: {
        [couponId: string]: ShopShare;
    };
};

const INITIAL_SHOP_SHARE_COUPON_DETAILS: ShopShareCouponDetailsFetchState = {
    shopShareCouponDetails: {},
};

const shopShareCouponDetails: FetchReducer<
    ShopShareCouponDetailsFetchState,
    ShopShareActionTypes.ShopShareCouponDetailsFetchAction
> = createFetchReducer(
    ShopShareActionTypes.SHOP_SHARE_COUPON_DETAILS_REQUEST,
    ShopShareActionTypes.SHOP_SHARE_COUPON_DETAILS_RESPONSE,
    ShopShareActionTypes.SHOP_SHARE_COUPON_DETAILS_RESET,
    (state: ShopShareCouponDetailsFetchState, action: Action) => {
        switch (action.type) {
            case ShopShareActionTypes.SHOP_SHARE_COUPON_DETAILS_RESPONSE:
                if (action.error) return state;

                return {
                    shopShareCouponDetails: {
                        ...state.shopShareCouponDetails,
                        ...(action.context?.couponId && {
                            [action.context.couponId]: action.payload,
                        }),
                    },
                };
            case ShopShareActionTypes.SHOP_SHARE_COUPON_VISIBILITY_RESPONSE:
                // eslint-disable-next-line no-case-declarations
                const {couponId, isCouponPublic} = action.context || {};

                if (action.error || !couponId) return state;

                return {
                    shopShareCouponDetails: {
                        ...state.shopShareCouponDetails,
                        [couponId]: {
                            ...state.shopShareCouponDetails[couponId],
                            isCouponPublic,
                        },
                    },
                };
            case ShopShareActionTypes.SHOP_SHARE_COUPON_DETAILS_RESET:
                return INITIAL_SHOP_SHARE_COUPON_DETAILS;
            default:
                return state;
        }
    },
    INITIAL_SHOP_SHARE_COUPON_DETAILS,
);

type CreateShareFetchState = {
    shareId: string | null;
};
const INITIAL_CREATE_SHARE: CreateShareFetchState = {
    shareId: null,
};

export const createShare = createFetchReducer(
    ShopShareActionTypes.CREATE_SHARE_REQUEST,
    ShopShareActionTypes.CREATE_SHARE_RESPONSE,
    ShopShareActionTypes.CREATE_SHARE_RESET,
    (state: CreateShareFetchState, action: Action) => {
        switch (action.type) {
            case ShopShareActionTypes.CREATE_SHARE_RESPONSE:
                if (action.error) return state;
                return {
                    shareId: action.payload.shareId,
                };
            case ShopShareActionTypes.CREATE_SHARE_RESET:
                return INITIAL_CREATE_SHARE;
            default:
                return state;
        }
    },
    INITIAL_CREATE_SHARE,
);

type UpdateShareFetchState = Record<string, unknown>;

export const updateShare = createFetchReducer(
    ShopShareActionTypes.UPDATE_SHOP_SHARE_REQUEST,
    ShopShareActionTypes.UPDATE_SHOP_SHARE_RESPONSE,
    ShopShareActionTypes.UPDATE_SHOP_SHARE_RESET,
    (state: UpdateShareFetchState) => state,
    {},
);

type DeleteShareFetchState = Record<string, unknown>;

export const deleteShare = createFetchReducer(
    ShopShareActionTypes.DELETE_SHOP_SHARE_REQUEST,
    ShopShareActionTypes.DELETE_SHOP_SHARE_RESPONSE,
    ShopShareActionTypes.DELETE_SHOP_SHARE_RESET,
    (state: DeleteShareFetchState) => state,
    {},
);

type CancelAndRefundShareFetchState = Record<string, unknown>;

export const cancelAndRefundShare = createFetchReducer(
    ShopShareActionTypes.CANCEL_AND_REFUND_SHOP_SHARE_REQUEST,
    ShopShareActionTypes.CANCEL_AND_REFUND_SHOP_SHARE_RESPONSE,
    ShopShareActionTypes.CANCEL_AND_REFUND_SHOP_SHARE_RESET,
    (state: CancelAndRefundShareFetchState) => state,
    {},
);

type SetGameInitiatorFetchState = Record<string, unknown>;

export const setGameInitiatorStatus = createFetchReducer(
    ShopShareActionTypes.SET_GAME_INITIATOR_REQUEST,
    ShopShareActionTypes.SET_GAME_INITIATOR_RESPONSE,
    ShopShareActionTypes.SET_GAME_INITIATOR_RESET,
    (state: SetGameInitiatorFetchState) => state,
    {},
);

type CouponVisibilityFetchState = Record<string, unknown>;

export const couponVisibility = createFetchReducer(
    ShopShareActionTypes.SHOP_SHARE_COUPON_VISIBILITY_REQUEST,
    ShopShareActionTypes.SHOP_SHARE_COUPON_VISIBILITY_RESPONSE,
    ShopShareActionTypes.SHOP_SHARE_COUPON_VISIBILITY_RESET,
    (state: CouponVisibilityFetchState) => state,
    {},
);

type SetEditShareNameFetchState = Record<string, unknown>;

export const setEditShareNameStatus = createFetchReducer(
    ShopShareActionTypes.EDIT_SHARE_NAME_REQUEST,
    ShopShareActionTypes.EDIT_SHARE_NAME_RESPONSE,
    ShopShareActionTypes.EDIT_SHARE_NAME_RESET,
    (state: SetEditShareNameFetchState) => state,
    {},
);

export const setMaxStakePerSystemData = createFetchReducer(
    ShopShareActionTypes.USE_MAX_STAKE_PER_SYSTEM_DATA_REQUEST,
    ShopShareActionTypes.USE_MAX_STAKE_PER_SYSTEM_DATA_RESPONSE,
    ShopShareActionTypes.USE_MAX_STAKE_PER_SYSTEM_DATA_RESET,
    (
        state: ShopShareActionTypes.MaxStakeDataState,
        action:
            | ShopShareActionTypes.SetUseMaxStakePerSystemDataAction
            | ShopShareActionTypes.ResetUseMaxStakePerSystemDataAction,
    ) => {
        switch (action.type) {
            case ShopShareActionTypes.USE_MAX_STAKE_PER_SYSTEM_DATA_RESPONSE:
                if (action.error) return state;
                return {
                    commission: action.payload.commission,
                    isMaxStake: action.payload.isMaxStake,
                    multiplied: action.payload.multiplied,
                    residualRatio: action.payload.residualRatio,
                    stake: action.payload.stake,
                    unitsPerCombination: action.payload.unitsPerCombination,
                };
            case ShopShareActionTypes.USE_MAX_STAKE_PER_SYSTEM_DATA_RESET:
                return undefined;
            default:
                return state;
        }
    },
    null,
);

type SetReceiptStatusFetchState = string;

export const setReceiptStatus = (
    state: SetReceiptStatusFetchState = "RECEIPT",
    action: Action,
): string => {
    switch (action.type) {
        case ShopShareActionTypes.SET_RECEIPT_RESULT:
            return "RECEIPT_RESULT";
        case ShopShareActionTypes.SET_RECEIPT:
            return "RECEIPT";
        default:
            return state;
    }
};

export type ShopShareState = {
    shopShare: FetchState<ShopShareFetchState>;
    createShare: FetchState<CreateShareFetchState>;
    shopShareCouponDetails: FetchState<ShopShareCouponDetailsFetchState>;
    setGameInitiatorStatus: FetchState<SetGameInitiatorFetchState>;
    couponVisibility: FetchState<CouponVisibilityFetchState>;
    setEditShareNameStatus: FetchState<SetEditShareNameFetchState>;
    setMaxStakePerSystemData: FetchState<ShopShareActionTypes.MaxStakeDataState>;
    updateShare: FetchState<UpdateShareFetchState>;
    deleteShare: FetchState<DeleteShareFetchState>;
    cancelAndRefundShare: FetchState<CancelAndRefundShareFetchState>;
    setReceiptStatus: string;
};

const shopSharesReducer: Reducer<ShopShareState, Action> = combineReducers({
    shopShare,
    createShare,
    shopShareCouponDetails,
    setGameInitiatorStatus,
    couponVisibility,
    setEditShareNameStatus,
    setMaxStakePerSystemData,
    updateShare,
    deleteShare,
    cancelAndRefundShare,
    setReceiptStatus,
});

export default shopSharesReducer;
