import {all, fork} from "redux-saga/effects";
import {contactInfoSaga} from "./contact-info/contactInfoSaga";
import {cardSaga} from "./card/cardSaga";
import {changePasswordSaga} from "./change-password/changePasswordSaga";
import {twoFactorSaga} from "./two-factor/twoFactorSaga";
import {avatarSaga} from "./avatar/avatarSaga";
import {smsRecoverySaga} from "./sms-recovery/smsRecoverySaga";

export function* accountSettingsSaga() {
    yield all([
        fork(avatarSaga),
        fork(contactInfoSaga),
        fork(cardSaga),
        fork(smsRecoverySaga),
        fork(changePasswordSaga),
        fork(twoFactorSaga),
    ]);
}
