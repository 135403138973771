/* eslint-disable no-param-reassign */

import {createSlice} from "@reduxjs/toolkit";

import type {PayloadAction} from "@reduxjs/toolkit";

interface ChampionsState {
    storeSearch: string;
    myPageToggle: "favoriter" | "butik";
}

const initialState: ChampionsState = {
    storeSearch: "",
    myPageToggle: "favoriter",
};

interface IStore {
    champions: ChampionsState;
}

export const championsSlice = createSlice({
    name: "champions",
    initialState,
    reducers: {
        setStoreSearch: (state, action: PayloadAction<string>) => {
            state.storeSearch = action.payload;
        },
        setMyPageToggle: (state, action: PayloadAction<"favoriter" | "butik">) => {
            state.myPageToggle = action.payload;
        },
    },
});

export const selectStoreSearch = (state: IStore) => state.champions.storeSearch;
export const selectMyPageToggle = (state: IStore) => state.champions.myPageToggle;
export const {setStoreSearch, setMyPageToggle} = championsSlice.actions;

export default championsSlice.reducer;
