import {t} from "@lingui/macro";
import type {AtgRequestError} from "@atg-shared/fetch-types";
import {createFetchReducer, createInitialState} from "@atg-shared/fetch-redux";
import type {ContactInfoState} from "@atg-payment-shared/account-settings-types";
import type {ReceiveUserAction} from "@atg-global-shared/user/userActions";
import {RECEIVE_USER} from "@atg-global-shared/user/userActionTypes";
import type {Action} from "../types";
import {
    CLEAR_CONTACT_INFO_ERROR,
    RESET_CONTACT_INFO_STATUS,
    SET_CONTACT_INFO_RESPONSE,
    SET_CONTACT_INFO_REQUEST,
} from "./contactInfoActions";

export const getErrorMessage = (error: AtgRequestError): string => {
    switch (error.response.meta.code) {
        case 400:
            return t`Ogiltig information i något av fälten.`;
        default:
            return t`Ett okänt tekniskt fel har uppstått.`;
    }
};

export const initialContactInfoState: ContactInfoState = createInitialState({
    data: {},
    errorMessage: null,
});

export const contactInfoReducer = createFetchReducer(
    SET_CONTACT_INFO_REQUEST,
    SET_CONTACT_INFO_RESPONSE,
    RESET_CONTACT_INFO_STATUS,
    (state: ContactInfoState, action: Action | ReceiveUserAction) => {
        switch (action.type) {
            case SET_CONTACT_INFO_RESPONSE: {
                if (!action.error) return state;

                return {
                    ...state,
                    errorMessage: getErrorMessage(action.payload),
                };
            }
            case CLEAR_CONTACT_INFO_ERROR: {
                return {
                    ...state,
                    errorMessage: null,
                };
            }
            case RECEIVE_USER: {
                return {
                    ...state,
                    data: action.payload.user.contactInfo,
                };
            }
            default:
                return state;
        }
    },
    initialContactInfoState,
);
