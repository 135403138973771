import uuidv4 from "uuid/v4";
import {createApi} from "@reduxjs/toolkit/query/react";
import {TechnicalChannel} from "@atg-horse-shared/bet-types";
import {pureFetch} from "@atg-shared/fetch";
import type {AtgRequestMultipleErrors} from "@atg-shared/fetch-types";
import {fetchAuthorized} from "@atg-shared/auth";
import {fetchBalance} from "@atg-global-shared/user/userActions";

type ExtraOptions = {
    retryAfterScopeUpgrade?: boolean;
    authorized?: boolean;
    memberFlowEnabled?: boolean;
};

export const storeApi = createApi({
    reducerPath: "api",
    baseQuery: async (args, _, extraOptions: ExtraOptions) => {
        const {
            retryAfterScopeUpgrade = true,
            authorized = true,
            memberFlowEnabled = true,
        } = (extraOptions ?? {
            retryAfterScopeUpgrade: true,
            authorized: true,
        }) as ExtraOptions;

        try {
            let data = null;

            if (authorized) {
                data = (
                    await fetchAuthorized(
                        args.url,
                        {
                            ...args,
                            body: JSON.stringify(args.body) || undefined,
                        },
                        {retryAfterScopeUpgrade, memberFlowEnabled},
                    )
                ).data;
            } else {
                data = (await pureFetch(args.url)).data;
            }

            return {data};
        } catch (unknownError: unknown) {
            const error = unknownError as AtgRequestMultipleErrors;

            return {error};
        }
    },
    tagTypes: [
        "balanceTag",
        "betTransaction",
        "liveBetReceipts",
        "trMedia",
        "favorites",
        "racingInfoGame",
        "rootQuickQuestions",
        "vmiv75_shop",
        "vmiv75_toplist",
        "vmiv75_own_rank",
    ],
    endpoints: (builder) => ({
        balance: builder.query<null, void>({
            queryFn: () => ({data: null}),
            providesTags: () => ["balanceTag"],
            onQueryStarted: (_, {dispatch}) => {
                dispatch(fetchBalance());
            },
        }),
        betTransactions: builder.query<null, unknown>({
            queryFn: () => ({data: null}),
            providesTags: (_) => ["betTransaction"],
        }),
        betsNotificationPreference: builder.query<null, unknown>({
            queryFn: () => ({data: null}),
        }),
        userNotificationPreference: builder.query<null, unknown>({
            queryFn: () => ({data: null}),
        }),
        leagueRegistrationStatus: builder.query<null, unknown>({
            queryFn: () => ({data: null}),
        }),
        shop: builder.query<null, unknown>({
            queryFn: () => ({data: null}),
        }),
    }),
});

type QueryHeaders = {queryHeaders?: Headers; customHeaders?: Headers};

export function mergeQueryHeaders({queryHeaders, customHeaders}: QueryHeaders) {
    const headers = new Headers({
        "request-id": uuidv4(),
        "business-operation-id": uuidv4(),
        "technical-channel": TechnicalChannel.WebAtgse,
    });

    if (queryHeaders) {
        queryHeaders.forEach((value: string, key: string) => headers.set(key, value));
    }

    if (customHeaders) {
        if (customHeaders instanceof Headers === false) {
            throw new TypeError(
                "mergeQueryHeaders custom headers can only be instance of Headers",
            );
        }

        customHeaders.forEach((value: string, key: string) => headers.set(key, value));
    }

    // TODO: should only return headers variable as Headers when atgFetch support it;
    // return headers

    // Could return Object.fromEntries(headers) but won't work in jest test due to only being supported from node v.18.
    const headersObj = {};
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    headers.forEach((value, key) => (headersObj[key] = value));
    return headersObj;
}

export const {useBalanceQuery} = storeApi;
