import dayjs from "dayjs";
import type {Location} from "history";
import queryString from "query-string";
import {deprecated_formatCurrency} from "@atg-shared/currency";
import {CouponTypes, type Coupon} from "@atg-horse-shared/coupon-types";
import type {ShareCoupon, ShareCouponRace} from "@atg-shop-shared/types/shareCoupon";

type SelectOption = {
    label: string;
    value: Coupon;
};

export const getFormattedSelections = ({bets}: ShareCouponRace): string | null => {
    if (bets.length === 0) {
        return "Inga hästar valda";
    }

    if (bets && bets[0] && bets.length === 1) {
        return bets[0].name ? `${bets[0].number} ${bets[0].name}` : `${bets[0].number}`;
    }

    if (bets.length > 1) {
        return bets.map((bet) => bet?.number).join(" ");
    }

    return null;
};

export const getFormattedReserves = ({reserves}: ShareCouponRace): string | null =>
    reserves.length > 0 ? reserves.map((reserve) => reserve?.number).join(" ") : null;

export const getFormattedCostCalculationWithoutSum = ({
    rows,
    stake,
    game,
}: ShareCoupon): string =>
    `${rows} ${
        game.type === "top7" ? "kombinationer" : "rader"
    } x ${deprecated_formatCurrency(stake)} = `;

export const getFormattedCost = ({betCost}: ShareCoupon): string =>
    deprecated_formatCurrency(betCost);

export const getFormattedCostCalculation = (coupon: ShareCoupon): string =>
    `${getFormattedCostCalculationWithoutSum(coupon)}${getFormattedCost(coupon)}`;

export const getImportCouponSelectorOptions = (
    coupons: Array<Coupon>,
    coupon: ShareCoupon,
): Array<SelectOption> => {
    const filteredPrivateCoupons = coupons.reduce(
        (acc: Array<SelectOption>, startedCoupon: Coupon) => {
            if (
                startedCoupon.id !== coupon.id &&
                startedCoupon.type === CouponTypes.PRIVATE &&
                startedCoupon.game.id === coupon.game.id
            ) {
                acc.push({
                    label: `Privat kupong #${acc.length + 1}`,
                    value: startedCoupon,
                });
            }

            return acc;
        },
        [],
    );

    const filteredShopCoupons = coupons.reduce(
        (acc: Array<SelectOption>, startedCoupon: Coupon) => {
            if (
                startedCoupon.id !== coupon.id &&
                startedCoupon.type === CouponTypes.SHOP_SHARED &&
                startedCoupon.game.id === coupon.game.id
            ) {
                acc.push({
                    label: startedCoupon.name || `Andelskupong #${acc.length + 1}`,
                    value: startedCoupon,
                });
            }

            return acc;
        },
        [],
    );

    return filteredPrivateCoupons.concat(filteredShopCoupons);
};

export const formatDate = (date: string): string =>
    dayjs(date).format("YYYY-MM-DD HH:mm");

export const isInitiallyExpanded = (location: Location, couponId: string): boolean =>
    queryString.parse(location.search).couponId === couponId;

export const getRankingGroups = (ranking: string) => {
    const rankings = ranking.split(",");

    const rankingGroups = rankings.map((rank) => {
        if (rank.includes("-")) {
            const [start, end] = rank.split("-").map(Number);
            return end - start + 1;
        }
        return 1;
    });

    return rankingGroups;
};
