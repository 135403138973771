import {v4 as uuid} from "uuid";
import type {ContactInfo} from "@atg-payment-shared/account-settings-types";
import type {MakeNullable} from "@atg/utils/typescript";
import {MEMBER_AUTH_URL} from "@atg-shared/service-url";
import {fetchAuthorized} from "@atg-shared/auth";

export const CONTACT_INFO_URL = `${MEMBER_AUTH_URL}/contactinfo`;

export type SaveContactInfoPayload = MakeNullable<
    ContactInfo & {businessOperationId: string}
>;

export const saveContactInfo = (contactInfo: SaveContactInfoPayload) => {
    const businessOperationId = uuid();
    const payload: SaveContactInfoPayload = {...contactInfo, businessOperationId};
    return fetchAuthorized(CONTACT_INFO_URL, {
        method: "PUT",
        body: JSON.stringify(payload),
    });
};
