import type {FetchAction} from "@atg-shared/fetch-types";
import type {
    SmsRecoveryAnswer,
    SmsRecoveryState,
} from "@atg-payment-shared/account-settings-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import * as SmsRecoveryApi from "./smsRecoveryApi";

export const FETCH_SMS_RECOVERY_QUESTIONS_REQUEST =
    "FETCH_SMS_RECOVERY_QUESTIONS_REQUEST";
export const FETCH_SMS_RECOVERY_QUESTIONS_RESPONSE =
    "FETCH_SMS_RECOVERY_QUESTIONS_RESPONSE";

export const FETCH_SMS_RECOVERY_ANSWER_REQUEST = "FETCH_SMS_RECOVERY_ANSWER_REQUEST";
export const FETCH_SMS_RECOVERY_ANSWER_RESPONSE = "FETCH_SMS_RECOVERY_ANSWER_RESPONSE";

export const SAVE_SMS_RECOVERY_REQUEST = "SAVE_SMS_RECOVERY_REQUEST";
export const SAVE_SMS_RECOVERY_RESPONSE = "SAVE_SMS_RECOVERY_RESPONSE";
export const RESET_SAVE_SMS_RECOVERY_STATUS = "RESET_SAVE_SMS_RECOVERY_STATUS";

export const DELETE_SMS_RECOVERY_REQUEST = "DELETE_SMS_RECOVERY_REQUEST";
export const DELETE_SMS_RECOVERY_RESPONSE = "DELETE_SMS_RECOVERY_RESPONSE";
export const RESET_DELETE_SMS_RECOVERY_STATUS = "RESET_DELETE_SMS_RECOVERY_STATUS";

export type FetchQuestionsAction = FetchAction<
    typeof FETCH_SMS_RECOVERY_QUESTIONS_REQUEST,
    typeof FETCH_SMS_RECOVERY_QUESTIONS_RESPONSE,
    SmsRecoveryState["questions"]["data"],
    SmsRecoveryState
>;

export type FetchAnswerAction = FetchAction<
    typeof FETCH_SMS_RECOVERY_ANSWER_REQUEST,
    typeof FETCH_SMS_RECOVERY_ANSWER_RESPONSE,
    {controlQuestion: SmsRecoveryAnswer},
    SmsRecoveryState
>;

export type SaveSmsRecoveryAction = FetchAction<
    typeof SAVE_SMS_RECOVERY_REQUEST,
    typeof SAVE_SMS_RECOVERY_RESPONSE,
    void,
    SmsRecoveryState,
    SmsRecoveryAnswer
>;

export type DeleteSmsRecoveryAction = FetchAction<
    typeof DELETE_SMS_RECOVERY_REQUEST,
    typeof DELETE_SMS_RECOVERY_RESPONSE,
    void,
    SmsRecoveryState
>;

export type ResetDeleteSmsRecoveryStatusAction = {
    type: typeof RESET_DELETE_SMS_RECOVERY_STATUS;
};

export type ResetSaveSmsRecoveryStatusAction = {
    type: typeof RESET_SAVE_SMS_RECOVERY_STATUS;
};

export const fetchQuestions = (): FetchQuestionsAction => ({
    type: FETCH,
    payload: {
        requestAction: FETCH_SMS_RECOVERY_QUESTIONS_REQUEST,
        receiveAction: FETCH_SMS_RECOVERY_QUESTIONS_RESPONSE,
        callApi: call(SmsRecoveryApi.fetchQuestions),
    },
});

export const fetchAnswer = (): FetchAnswerAction => ({
    type: FETCH,
    payload: {
        requestAction: FETCH_SMS_RECOVERY_ANSWER_REQUEST,
        receiveAction: FETCH_SMS_RECOVERY_ANSWER_RESPONSE,
        callApi: call(SmsRecoveryApi.fetchAnswer),
    },
});

export const saveAnswer = (answer: SmsRecoveryAnswer): SaveSmsRecoveryAction => ({
    type: FETCH,
    payload: {
        requestAction: SAVE_SMS_RECOVERY_REQUEST,
        receiveAction: SAVE_SMS_RECOVERY_RESPONSE,
        callApi: call(SmsRecoveryApi.saveAnswer, answer),
        context: answer,
    },
});

export const deleteAnswer = (): DeleteSmsRecoveryAction => ({
    type: FETCH,
    payload: {
        requestAction: DELETE_SMS_RECOVERY_REQUEST,
        receiveAction: DELETE_SMS_RECOVERY_RESPONSE,
        callApi: call(SmsRecoveryApi.deleteAnswer),
    },
});

export const resetSaveAnswerStatus = (): ResetSaveSmsRecoveryStatusAction => ({
    type: RESET_SAVE_SMS_RECOVERY_STATUS,
});

export const resetDeleteAnswerStatus = (): ResetDeleteSmsRecoveryStatusAction => ({
    type: RESET_DELETE_SMS_RECOVERY_STATUS,
});

export type SmsRecoveryAction =
    | FetchQuestionsAction
    | FetchAnswerAction
    | SaveSmsRecoveryAction
    | DeleteSmsRecoveryAction
    | ResetDeleteSmsRecoveryStatusAction
    | ResetSaveSmsRecoveryStatusAction;
