import {t} from "@lingui/macro";
import {combineReducers} from "redux";
import type {Reducer} from "redux";
import type {AtgRequestError} from "@atg-shared/fetch-types";
import type {AvatarState} from "@atg-payment-shared/account-settings-types";
import {createFetchReducer, createInitialState} from "@atg-shared/fetch-redux";
import {
    type AvatarAction,
    AVATAR_REMOVE_REQUEST,
    AVATAR_REMOVE_RESPONSE,
    AVATAR_REMOVE_RESET,
    AVATAR_SAVE_REQUEST,
    AVATAR_SAVE_RESPONSE,
    AVATAR_SAVE_RESET,
    OPEN_CHANGE_AVATAR_MODAL,
} from "./avatarActions";

const getErrorMessage = (payload: AtgRequestError) => {
    switch (payload.response.meta.code) {
        default:
            return t`Något gick fel. Försök igen senare.`;
    }
};

const remove = createFetchReducer(
    AVATAR_REMOVE_REQUEST,
    AVATAR_REMOVE_RESPONSE,
    AVATAR_REMOVE_RESET,
    (state, action) => {
        switch (action.type) {
            case AVATAR_REMOVE_RESPONSE:
                if (!action.error) return state;

                return {
                    ...state,
                    errorMessage: getErrorMessage(action.payload),
                };
            case AVATAR_REMOVE_RESET:
                return {...state, errorMessage: null};

            default:
                return state;
        }
    },
    createInitialState({}),
);

const upload = createFetchReducer(
    AVATAR_SAVE_REQUEST,
    AVATAR_SAVE_RESPONSE,
    AVATAR_SAVE_RESET,
    (state, action) => {
        switch (action.type) {
            case AVATAR_SAVE_RESPONSE:
                if (!action.error) return state;

                return {
                    ...state,
                    errorMessage: getErrorMessage(action.payload),
                };
            case AVATAR_SAVE_RESET:
                return {...state, errorMessage: null};

            default:
                return state;
        }
    },
    createInitialState({}),
);

const triggerChangeAvatarModal = (state = false, action: AvatarAction) => {
    switch (action.type) {
        case OPEN_CHANGE_AVATAR_MODAL:
            return true;
        default:
            return state;
    }
};

export const avatarReducer: Reducer<AvatarState, AvatarAction> = combineReducers({
    upload,
    remove,
    triggerChangeAvatarModal,
});
