import type {
    ContactOptions,
    ContactOptionsState,
    EditContactOptionsPayload,
} from "@atg-payment-shared/account-settings-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import type {FetchAction} from "@atg-shared/fetch-types";
import * as ContactOptionsApi from "./contactOptionsApi";

export const FETCH_CONTACT_OPTIONS_REQUEST = "FETCH_CONTACT_OPTIONS_REQUEST";
export const FETCH_CONTACT_OPTIONS_RESPONSE = "FETCH_CONTACT_OPTIONS_RESPONSE";

export const EDIT_CONTACT_OPTIONS_REQUEST = "EDIT_CONTACT_OPTIONS_REQUEST";
export const EDIT_CONTACT_OPTIONS_RESPONSE = "EDIT_CONTACT_OPTIONS_RESPONSE";

export type ContactOptionsResponse = Omit<ContactOptions, "SHOP_SHARES"> & {
    shopShare: boolean;
};

export type EditContactOptionsAction = FetchAction<
    typeof EDIT_CONTACT_OPTIONS_REQUEST,
    typeof EDIT_CONTACT_OPTIONS_RESPONSE,
    Partial<ContactOptionsResponse>,
    ContactOptionsState
>;

export type FetchContactOptionsAction = FetchAction<
    typeof FETCH_CONTACT_OPTIONS_REQUEST,
    typeof FETCH_CONTACT_OPTIONS_RESPONSE,
    ContactOptionsResponse,
    ContactOptionsState
>;

export const editContactOptions = (
    payload: EditContactOptionsPayload,
): EditContactOptionsAction => ({
    type: FETCH,
    payload: {
        requestAction: EDIT_CONTACT_OPTIONS_REQUEST,
        receiveAction: EDIT_CONTACT_OPTIONS_RESPONSE,
        callApi: call(ContactOptionsApi.editContactOptions, payload),
    },
});

export const fetchContactOptions = (): FetchContactOptionsAction => ({
    type: FETCH,
    payload: {
        requestAction: FETCH_CONTACT_OPTIONS_REQUEST,
        receiveAction: FETCH_CONTACT_OPTIONS_RESPONSE,
        callApi: call(ContactOptionsApi.fetchContactOptions),
    },
});

export type ContactOptionsAction = EditContactOptionsAction | FetchContactOptionsAction;
