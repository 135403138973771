import {memoize} from "lodash/fp";
import TTLCache from "@atg/utils/TTLCache";
import atgRequest from "@atg-shared/fetch";
import type {AtgResponse} from "@atg-shared/fetch-types";
import type {Card} from "@atg-payment-shared/account-settings-types";
import {ATG_CARD_V3_URL} from "@atg-shared/service-url";

const CACHE_TIME = 30 * 60 * 1000;
const ttlCache: TTLCache<string, Card> = new TTLCache(CACHE_TIME);

const memoizedAtgRequest = memoize(atgRequest);
memoizedAtgRequest.cache = ttlCache;

export const fetchCard = (): Promise<AtgResponse<Card>> =>
    memoizedAtgRequest(ATG_CARD_V3_URL);

export const orderCard = (): Promise<AtgResponse<Card>> =>
    atgRequest(ATG_CARD_V3_URL, {
        method: "POST",
    });

export const blockCard = (): Promise<AtgResponse<Card>> =>
    atgRequest(ATG_CARD_V3_URL, {
        method: "PUT",
        body: JSON.stringify({
            status: "BLOCKED_BY_OWNER",
        }),
    });

export const clearCache = () => {
    ttlCache.clear();
};
