import {merge} from "lodash";
import {createFetchReducer, createInitialState} from "@atg-shared/fetch-redux";
import type {
    ContactOptions,
    ContactOptionsState,
} from "@atg-payment-shared/account-settings-types";
import type {Action} from "../types";
import {
    EDIT_CONTACT_OPTIONS_RESPONSE,
    FETCH_CONTACT_OPTIONS_REQUEST,
    FETCH_CONTACT_OPTIONS_RESPONSE,
} from "./contactOptionsActions";

export const initialContactOptionsState = createInitialState({
    data: {
        HORSE_BETTING: {
            EMAIL: {
                optIn: false,
            },
            SMS: {
                optIn: false,
            },
            POSTAL: {
                optIn: false,
            },
        },
        SPORTSBOOK: {
            EMAIL: {
                optIn: false,
            },
            SMS: {
                optIn: false,
            },
            POSTAL: {
                optIn: false,
            },
        },
        CASINO: {
            EMAIL: {
                optIn: false,
            },
            SMS: {
                optIn: false,
            },
            POSTAL: {
                optIn: false,
            },
        },
        SHOP_SHARES: {
            EMAIL: {
                optIn: false,
            },
            SMS: {
                optIn: null,
            },
            POSTAL: {
                optIn: null,
            },
        },
    },
});

export const contactOptionsReducer = createFetchReducer(
    FETCH_CONTACT_OPTIONS_REQUEST,
    FETCH_CONTACT_OPTIONS_RESPONSE,
    null,
    (state: ContactOptionsState, action: Action) => {
        switch (action.type) {
            case EDIT_CONTACT_OPTIONS_RESPONSE:
            case FETCH_CONTACT_OPTIONS_RESPONSE: {
                if (action.error) return {data: {...state.data}};

                // When the newly registered user has not set the marketing options,
                // The response will be an object with all values set to false.
                // Edit contact options response is a partial response, so we need to merge it with the current state.
                const options: ContactOptions = merge({}, state.data, action.payload, {
                    SHOP_SHARES: {
                        EMAIL: {
                            optIn:
                                action.payload.shopShare ??
                                state.data.SHOP_SHARES.EMAIL.optIn,
                        },
                        SMS: {optIn: null},
                        POSTAL: {optIn: null},
                    },
                });

                return {data: options};
            }
            default:
                return state;
        }
    },
    initialContactOptionsState,
);
